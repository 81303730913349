import { GqlVideoFragment } from '../apollo/generated/graphql';
import {
  useChannelAccessButton,
  useFormatMessageWithPrice,
  useVideoPricing,
} from '.';
import { useVideoAccessAction } from './useVideoAccessAction';
import { AccessAction } from '../types/enums';

type UseVideoAccessButton =
  | {
      text: string;
      handler(): void;
    }
  | undefined;

export function useVideoAccessButton(
  video: GqlVideoFragment,
): UseVideoAccessButton {
  const { minPrice, currency, rentPrice, salePrice } = useVideoPricing(video);
  const { formatMessageWithPrice } = useFormatMessageWithPrice();
  const channelAccessButton = useChannelAccessButton();
  const videoAccessAction = useVideoAccessAction(video);

  if (
    videoAccessAction === AccessAction.Subscribe ||
    videoAccessAction === AccessAction.PricingPlan
  ) {
    return channelAccessButton;
  }

  if (videoAccessAction === AccessAction.Buy) {
    return {
      text: formatMessageWithPrice(
        'widget.overlay-buttons.buy',
        salePrice,
        currency,
      ),
      handler() {
        alert('open sale lightbox');
      },
    };
  }

  if (videoAccessAction === AccessAction.Rent) {
    return {
      text: formatMessageWithPrice(
        'widget.overlay-buttons.rent',
        rentPrice,
        currency,
      ),
      handler() {
        alert('open rent lightbox');
      },
    };
  }

  if (videoAccessAction === AccessAction.BuyOrRent) {
    return {
      text: formatMessageWithPrice(
        'widget.overlay-buttons.buy-from',
        minPrice,
        currency,
      ),
      handler() {
        alert('open rent/sale lightbox');
      },
    };
  }
}
