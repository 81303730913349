import { useStyles } from '@wix/tpa-settings/react';
import React, { FC } from 'react';
import classNames from 'classnames';
import stylesParams from '../../../../../stylesParams';
import { LAYOUT_STYLE_VALUES } from '@wix/wix-vod-constants/app-settings';
import s from './PlayerActionButton.scss';

type Props = {
  icon: React.ReactNode;
  text: string;
  textShort: string;
  onClick: () => void;
  ariaLabel?: string;
  dataHook?: string;
};

const useLayoutStyle = () => {
  const styles = useStyles();
  const layoutStyle = styles.get(stylesParams.layoutStyle);
  return {
    rounded: layoutStyle === LAYOUT_STYLE_VALUES.ROUND,
  };
};

export const PlayerActionButton: FC<Props> = ({
  icon,
  text,
  textShort,
  onClick,
  ariaLabel,
  dataHook,
}) => {
  const { rounded } = useLayoutStyle();
  return (
    <button
      className={classNames(s.buttonAction, { [s.rounded]: rounded })}
      onClick={onClick}
      aria-label={ariaLabel}
      data-hook={dataHook}
    >
      <span
        className={s.buttonLabel}
        data-label={text}
        data-short-label={textShort}
      />
      <span className={s.iconContainer} aria-hidden>
        <span className={s.icon}>{icon}</span>
      </span>
    </button>
  );
};
